import React from "react";
export default function Chart({ reviews, jobDetails }) {

  return (
    <div>
      {reviews && reviews.length > 0 && jobDetails && jobDetails.length && (
        <>
        <div
          className="reviews_information"
          data-reviews={JSON.stringify(reviews)}
          data-job-details={JSON.stringify(jobDetails)}
        ></div>
         <div className="toggle-container">
        <div className="button-group">
          <button className="button button-weekly active">Weekly</button>
          <button className="button button-monthly">Monthly</button>
        </div>
      </div>
      <div className="canvas-grid">
        <div className="canvas-container" id="canvas-container"></div>
      </div>
      <div className="flex-box">
        <div className="job-detail-container flex-child "
        // style={{width: "100%"}}
        >
          <div id="job-count-detail-canvas" style= {{margin: "auto"}}></div>
        </div>
        <div className="flex-child " style={{width: '100%'}}>
          <div>
            <b>Running Jobs:</b>{" "}
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {jobDetails[0].locked_jobs.map((locked_job, idx) => {
              return  <span
                key={idx}
                className="badge badge-success"
                data-placement="top"
                tabIndex="0"
                data-toggle="tooltip"
                data-mdb-custom-class="text-sm"
              >
                {locked_job["queue"]}
              </span>;
            })}
          </div>
        </div>
      </div>

      <div
        className="job-detail-container"
      >
        <div
         style={{ maxWidth: "calc(100vw - 300px)", overflowX: scroll }}
        className="job-detail-canvas" id="pending-job-detail-canvas"></div>
      </div>

      <div
        className="job-detail-container"
      >
        <div
         style={{ maxWidth: "calc(100vw - 300px)", overflowX: scroll }}
        className="job-detail-canvas" id="failed-job-detail-canvas"></div>
      </div>
        </>
      )}
    </div>
  );
}
