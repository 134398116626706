import Select from "react-select";
import React from "react";
import {
  appList,
  countryList,
  shopifyPlanList,
  categoryList,
  reviewList,
  planList,
  appBlockList,
  installList,
  passwordProtectedList,
  searchInList
} from "./data";
import { useState, useEffect, useRef } from "react";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();
export default function FilterSection({
  searchIn,
  setSearchIn,
  selectedApps,
  setSelectedApps,
  selectedCountries,
  setSelectedCountries,
  selectedShopifyPlans,
  setSelectedShopifyPlans,
  selectedCategories,
  setSelectedCategories,
  selectedReview,
  setSelectedReview,
  selectedPlan,
  setSelectedPlan,
  storeCreatedAfter,
  setStoreCreatedAfter,
  storeCreatedBefore,
  setStoreCreatedBefore,
  selectedAppBlock,
  setSelectedAppBlock,
  selectedInstall,
  setSelectedInstall,
  isPasswordProtected,
  setIsPasswordProtected,
}) {
  const divRef = useRef(null);

  const [isSelectorDisabled, setIsSelectorDisabled] = useState(true);

  useEffect(() => {
    let isDisabled = false;
    if (!selectedApps) return;
    selectedApps.forEach((e) => {
      if (!["VIA", "VSK"].includes(e.value)) {
        isDisabled = true;
      }
    });
    setIsSelectorDisabled(isDisabled);
  }, [selectedApps]);

  const [closeSidebar, setCloseSidebar] = useState(false);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.classList.toggle("hide-sidebar");
    }
  }, [closeSidebar]);


  return (
    <div 
      ref={divRef}
      className={`d-flex gap-10 flex-column w-250 filter-section `}
    >
      <div className="sidebar-svg"  onClick={() => {
            setCloseSidebar(!closeSidebar);
          }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="Md8ey kDN2g"><g class="a0" opacity="1" transform="translate(6,11.9984) translate(-0.207107,-3.53556)"><rect class="a1" width="2" height="6" rx="0.5" fill="currentColor" opacity="1" transform="translate(5.20711,3.53698) rotate(-90) translate(-1,-3)"></rect><rect class="a2" width="2" height="5" rx="0.5" fill="currentColor" opacity="1" transform="translate(1.41421,3.53553) rotate(-135) translate(-1,-1)"></rect><rect class="a3" width="2" height="5" rx="0.5" fill="currentColor" opacity="1" transform="translate(1.41421,3.53558) rotate(-45) translate(-1,-1)"></rect></g><g class="a4" opacity="0" fill="none" transform="translate(18,11.9984) rotate(180) translate(-0.207107,-3.53556)"><rect class="a5" width="2" height="0" rx="0.5" fill="currentColor" opacity="1" transform="translate(5.20711,3.53698) rotate(-90) translate(-1,-3)"></rect><rect class="a6" width="2" height="2" rx="0.5" fill="currentColor" opacity="1" transform="translate(1.41421,3.53553) rotate(-135) translate(-1,-1)"></rect><rect class="a7" width="2" height="2" rx="0.5" fill="currentColor" opacity="1" transform="translate(1.41421,3.53558) rotate(-45) translate(-1,-1)"></rect></g><rect class="a8" width="2" height="12" rx="0.5" fill="currentColor" opacity="1" transform="translate(17,12) rotate(180) translate(-1,-6)"></rect></svg>

      </div>
      <div className="filter-list d-flex gap-10 flex-column w-250">
      <div>
          <label>Search In</label>
          <Select
            value={searchIn}
            // isMulti
            isClearable={true}
            name="Search In"
            options={searchInList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              setSearchIn(e);
            }}
            components={animatedComponents}
          />
        </div>
        <div>
          <label>Apps</label>
          <Select
            value={selectedApps}
            isMulti
            isClearable={true}
            name="Apps"
            options={appList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(e) => {
              setSelectedApps(e);
            }}
            components={animatedComponents}
          />
        </div>
        <div>
          <label>Countries</label>
          <Select
            onChange={(e) => {
              setSelectedCountries(e);
            }}
            isMulti
            isClearable={true}
            value={selectedCountries}
            name="Themes"
            options={countryList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>
        <div>
          <label>Shopify Plans</label>
          <Select
            onChange={(e) => {
              setSelectedShopifyPlans(e);
            }}
            isMulti
            isClearable={true}
            value={selectedShopifyPlans}
            name="Themes"
            options={shopifyPlanList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>

        <div>
          <label>Review</label>
          <Select
            onChange={(e) => {
              setSelectedReview(e);
            }}
            value={selectedReview}
            isClearable={true}
            name="Themes"
            options={reviewList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>

        <div>
          <label>Plan Status</label>
          <Select
            onChange={(e) => {
              setSelectedPlan(e);
            }}
            value={selectedPlan}
            isClearable={true}
            name="Themes"
            options={planList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>
        <div>
          <label>App Block</label>
          <Select
            onChange={(e) => {
              setSelectedAppBlock(e);
            }}
            value={selectedAppBlock}
            isClearable={true}
            name="App Block"
            isDisabled={
              !(selectedApps.length && selectedApps[0].value == "VSK")
            }
            options={appBlockList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>

        <div>
          <label>Store Categories</label>
          <Select
            onChange={(e) => {
              setSelectedCategories(e);
            }}
            isDisabled={
              !(selectedApps.length && selectedApps[0].value == "VSK")
            }
            isMulti
            isClearable={true}
            value={selectedCategories}
            name="Themes"
            options={categoryList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>

        <div>
          <label>Installed</label>
          <Select
            onChange={(e) => {
              setSelectedInstall(e);
            }}
            value={selectedInstall}
            isClearable={true}
            name="Themes"
            options={installList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>
        <div>
          <label>Password Protected</label>
          <Select
            onChange={(e) => {
              setIsPasswordProtected(e);
            }}
            value={isPasswordProtected}
            isClearable={true}
            name="Password Protected"
            options={passwordProtectedList}
            className="basic-multi-select"
            classNamePrefix="select"
            components={animatedComponents}
          />
        </div>
        <div>
          <label>Store created after</label>
          <input
            className="padding-6"
            style={{ width: "100%" }}
            type="date"
            value={storeCreatedAfter}
            onChange={(e) => {
              setStoreCreatedAfter(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Store created before</label>
          <input
            className="padding-6"
            style={{ width: "100%" }}
            type="date"
            value={storeCreatedBefore}
            onChange={(e) => {
              setStoreCreatedBefore(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
